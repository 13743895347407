@import "bootstrap/scss/bootstrap";
/* You can add global styles to this file, and also import other style files */

/* show search in table */
span.ngb-highlight-table-search {
	font-weight: bold;
	background-color: yellow;
}

/*
.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
	background-color: rgb(0, 26, 255);
  }*/